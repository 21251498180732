
import { Component, Vue } from 'vue-property-decorator'

@Component({
  name: 'Blank'
})
export default class Blank extends Vue {
  private isLoading = true
  private result = ''

  created () {
    this.doAction()
  }

  doAction () {
    const name = this.$route.name || ''
    const url = this.$route.query.url || ''
    switch (name) {
      case '3dMap':
        if (url) {
          window.open(url as string)
        }
        this.$router.back()
        break
      case 'intercom':
      case 'patrol':
        this.getUrl(name)
        break
    }
  }

  getUrl (ssoid: string) {
    this.isLoading = true
    this.result = ''
    this.$axios.get(this.$apis.common.selectSsotemUrl, {
      ssoId: ssoid
    }).then(res => {
      window.open(res)
      this.$router.back()
    }).catch(() => {
      this.result = 'fail'
    }).finally(() => {
      this.isLoading = false
    })
  }
}
